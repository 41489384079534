import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import revenue from '../../../assets/images/home/Revenue Growth 1.svg';
import optimization from '../../../assets/images/home/Cost Optimization 1.svg';
import capital from '../../../assets/images/home/Capital Management 1.svg';

export default function Financial() {
    return (
        <section className='section-financial'>
            <Container className='pad'>
                <Row>
                    <Col className='title-holder'>
                       
                            <h1>
                                FINANCIAL<br />
                                <span>STRATEGY</span>
                            </h1>
                       
                    </Col>
                    <Col>

                        <table className='table-inv'>
                            <tr>
                                <td>
                                    <img className='img-fluid' src={revenue} alt='Revenue' />
                                </td>
                                <td>
                                    <h2>REVENUE GROWTH</h2>
                                    <p>Strengthen our core and continue to invest in growth opportunities.</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <img className='img-fluid' src={optimization} alt='Optimization' />
                                </td>
                                <td>
                                    <h2>REVENUE GROWTH</h2>
                                    <p>Strengthen our core and continue to invest in growth opportunities.</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <img className='img-fluid' src={capital} alt='Capital' />
                                </td>
                                <td>
                                    <h2>REVENUE GROWTH</h2>
                                    <p>Strengthen our core and continue to invest in growth opportunities.</p>
                                </td>
                            </tr>
                        </table>

                    </Col>
                </Row>
            </Container>
        </section>
    )
}
