import React from 'react'
import { Container, Image, Nav, NavDropdown, Navbar } from 'react-bootstrap'
import logo from '../assets/images/logo.png';
export default function Header() {
  return (
    <Navbar expand="lg" bg={'transparent'} className="bg-body-tertiary main-nav">

      <Navbar.Brand href="#home">
        <Image className='img-fluid' src={logo} />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="me-auto nav-fill">
          <Nav.Link href="#home">Home</Nav.Link>
          <Nav.Link href="#link">Business Review</Nav.Link>
          <Nav.Link href="#link">Sustainability</Nav.Link>
          <Nav.Link href="#link">Our Governance</Nav.Link>
          <Nav.Link href="#link">Our Finance</Nav.Link>
        </Nav>
      </Navbar.Collapse>
      <Nav.Item className='nav-share d-none d-sm-none d-md-block'>
        <svg xmlns="http://www.w3.org/2000/svg" height="2.8rem" viewBox="0 0 448 512"><path d="M352 224c53 0 96-43 96-96s-43-96-96-96s-96 43-96 96c0 4 .2 8 .7 11.9l-94.1 47C145.4 170.2 121.9 160 96 160c-53 0-96 43-96 96s43 96 96 96c25.9 0 49.4-10.2 66.6-26.9l94.1 47c-.5 3.9-.7 7.8-.7 11.9c0 53 43 96 96 96s96-43 96-96s-43-96-96-96c-25.9 0-49.4 10.2-66.6 26.9l-94.1-47c.5-3.9 .7-7.8 .7-11.9s-.2-8-.7-11.9l94.1-47C302.6 213.8 326.1 224 352 224z" /></svg>
      </Nav.Item>
    </Navbar>
  )
}
