import React from 'react'
import Header from './Header';
import Footer from './Footer';

export default function MainTemplate({children}) {
  return (<>
  <Header/>
  { children }
  <Footer />
  </>
  );
}
