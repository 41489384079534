import React from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import DownloadBox from './DownloadBox';

export default function Downloads() {

    const downloads = [
        { name: "Full Integrated Report 2023", link: "#" },
        { name: "Snapshot 2023", link: "#" },
        { name: "Our Progres", link: "#" },
        { name: "Bespoke Value", link: "#" },
        { name: "Our Sustainabil-ity Journey", link: "#" },
        { name: "Notice of 38th AGM", link: "#" },
        { name: "AGM Administra-tive Guide", link: "#" },
        { name: "Financial Report 2023", link: "#" },
        { name: "Words from the Leaders", link: "#" },
        { name: "Business Reviews", link: "#" },
        { name: "Our Corporate Governa-nce", link: "#" },
        { name: "Other Information", link: "#" },
        { name: "Circular to Shareholders", link: "#" },
        { name: "Proxy Form", link: "#" },
    ];

    return (
        <section id='sectiondownload' className='section-downloads'>

            <div className='bg-1'>
                <Container>
                    <div className='intro'>
                        <h1>DOWNLOADS <span className='language'>EN | BM</span></h1>
                        <p>Select one or multiple chapters and then click on DOWNLOAD NOW to proceed.</p>

                    </div>

                    <div className='d-none d-sm-none d-md-block box-holder'>
                        {/* {downloads.map(down => {
                            return <div className='box'>
                                <h1>{down.name}</h1>
                            </div>;
                        })} */}

                        {/* <ul>  {downloads.map(down => {
                            return <li className='gap'> <div className='box-li'>
                                <h1>{down.name}</h1>
                            </div></li>;
                        })}
                        </ul> */}
                    </div>
                    {/* <Container className='d-sm-block d-md-none box-holder'> */}

                    <Container className='box-holder'>
                        {/* <Row>  {downloads.map((down, i) => {
                            return <><Col lg={2} md={6} sm={12}> <div className='box'>
                                <h1>{down.name}</h1>
                            </div></Col>
                     
                            </>
                            ;
                        })}
                        </Row> */}
                       
                    </Container>

                    <Container>
                    <div className='layer-holder'>
                         

                            { downloads.slice(0, downloads.length/2).map((item) => {
                                return   <DownloadBox name={item.name} link={item.link} />
    
                            } ) }
                            
                        </div>
                        <div className='layer-holder'>
                        { downloads.slice( downloads.length/2, downloads.length).map((item) => {
                                  return   <DownloadBox name={item.name} link={item.link} />
    
                            } ) }
                        </div>
                    </Container>

                    <button className='btn btn-download btn-orange'>Download</button>
                </Container>

            </div>

        </section>
    )
}
