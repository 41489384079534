import React from 'react'
import { Container } from 'react-bootstrap'

export default function Banner() {
    return (
        <section className='home-banner'>
            <div className='black-overlay'>
            <div className='position-relative overflow-hidden p-3 p-md-5 m-md-3  '>
                <Container>
                    <h1>YOUR NEXT IS <span>NOW</span></h1>
                    <h2>Integrated Annual Report 2023</h2>

                    <a className='btn btn-download btn-blue' href='#sectiondownload'>Download</a>
                </Container>

            </div>
            </div>
        </section>
    )
}
