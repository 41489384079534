import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import quote from '../../../assets/images/home/quote.svg';
import chairman from '../../../assets/images/home/chairman.png';

export default function Chairman() {

    const revenues = [
        {
            name: "GROUP REVENUE",
            amount: 12118.1
        },
        {
            name: "GROUP PATAMI",
            amount: 1143.3
        },
        {
            name: "EBIT",
            amount: 2090.2
        },
        {
            name: "TOTAL DIVIDEND PAYOUT",
            amount: 627.5
        }
    ];

    return (
        <section className='section-chairman'>
            
            <div className='c-1'>
                <div className='side d-none d-sm-none d-md-block'>
            <img className='img-fluid' src={chairman} alt='' />
            </div>
                    <Row>
                    <Col className='d-block d-sm-none ' md={6} sm={12}>
                            <img className='img-fluid' src={chairman} alt='' />
                        </Col>
                        <Col md={6} sm={12}>
                            <h1>MESSAGE FROM<br />
                                OUR <span>CHAIRMAN</span></h1>
                            <p><img className='img-fluid' src={quote} alt='quote' />
                                &nbsp;2022 has been a very good year for TM. We have achieved positive performance in multiple aspects of our business, with strong financial growth, infrastructure modernisation and expansion and notable progress in our journey. With a clear understanding that the value of technology is only as good as the people it serves, we have dedicated ourselves to putting people at the centre of everything that we do.</p>
                            <h3>Tan Sri Mohammed Azlan Hashim</h3>
                            <h4>Chairman</h4>
                            <button className='btn-download btn-blue'>
                                Read More
                            </button>
                        </Col>
                        <Col className='d-none d-sm-none d-md-block' md={6} sm={12}>
                            {/* <img className='img-fluid' src={chairman} alt='' /> */}
                            &nbsp;
                        </Col>
                    </Row>

            </div>
            <div className='c-2'>
              
                    <Row className='box-holder'>

                        {revenues.map(rv => {
                            return <Col className='cus-col' md={3} sm={6} >
                                <div className='box'>
                                    <h5>{rv.name}</h5>
                                    <h4>RM{rv.amount}</h4>
                                    <h6>million</h6>
                                </div>
                            </Col>;
                        })}

                    </Row>
                
            </div>
        </section>
    )
}
