import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import quote from '../../../assets/images/home/quote.svg';
import gcfo from '../../../assets/images/home/gcfo.png';

export default function Gcfo() {
    return (
        <section className='section-gcfo'>
            <div className='bg-2'>
                <Container fluid>
                    <div className='side d-none d-sm-none d-md-block'>
                    <img className='img-fluid' src={gcfo} alt='GCFO' />
                    </div>
                    <Row>
                    <Col className='d-block d-sm-none ' md={6} sm={12}>
                            <img className='img-fluid' src={gcfo} alt='GCFO' />
                        </Col>
                        <Col md={6} sm={12}>
                            <div className='content'>
                            <h1><span>GCFO’S</span> REVIEW</h1>
                            <p><img className='img-fluid' src={quote} alt='quote' />
                                &nbsp;2022 was the second year of our transformation programme that started in 2021 and we are delighted with the momentous progress we’ve seen for the year. We maintained a positive growth momentum in profitability for the year and accelerated our nation building efforts.</p>

                            <h3>Razidan Ghazalli</h3>
                            <h4>Group Chief Financial Officer</h4>
                            <button className='btn-download btn-blue'>
                                Read More
                            </button>
                            </div>
                        </Col>
                        <Col className='d-none d-sm-none d-md-block' md={6} sm={12}>
                            &nbsp;
                        </Col>
                    </Row>
                </Container>
            </div>
        </section>
    )
}
