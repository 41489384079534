import logo from './logo.svg';
import './App.css';
import Header from './templates/Header';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import MainTemplate from './templates/MainTemplate';
import Home from './pages/home/Home';

function App() {
  return (
    <>
   <BrowserRouter>
   <MainTemplate>
    <Routes>
      <Route path='/' element={ <Home /> } />
    </Routes>
   </MainTemplate>
   </BrowserRouter>
    </>
  );
}

export default App;
