import React from 'react';
import { Carousel, Col, Container, Row } from 'react-bootstrap';
import employeeSvg from '../../../assets/images/home/Clip path group.svg';

export default function Priorities() {
    return (
        <section className='section-priorities'>
            <Container>
                <Row className='intro'>
                    <Col>
                        <h1>STAKEHOLDER<br />
                            <span>PRIORITIES</span></h1>
                    </Col>
                    <Col>
                        <p>People remain at the core of our value creation story. By closely listening to them, each decision we make is shaped by how we can improve the lives of our stakeholders and society today and into the future.</p>
                    </Col>
                </Row>
            </Container>
            <Carousel data-bs-theme="dark" controls={true}> 
                <Carousel.Item>
                    <div className='banner'>
                        <img src={employeeSvg} className='img-fluid' alt='Emplyee' />
                            <h3>EMPLOYEES</h3>
                            <h4>WHY THEY ARE IMPORTANT</h4>
                            <p>We rely on the skills and knowledge of Warga TM to drive our aspirations forward. Their hard work and dedication have made our digital and nation building objectives possible. At the same time, we are responsible for providing employees with livelihoods and fulfilling career paths.</p>
                    <button className='btn btn-download btn-blue'>View More</button>
                    </div>
                </Carousel.Item>
                <Carousel.Item>
             
                <div className='banner'>
                        <img src={employeeSvg} className='img-fluid' alt='Emplyee' />
                            <h3>CUSTOMER</h3>
                            <h4>WHY THEY ARE IMPORTANT</h4>
                            <p>The support of our customers is critical to our business sustainability. Their trust in our products and services directly contributes to our long-term performance and enables us to achieve our nation building aspirations. At the same time, we empower our customers with essential services to thrive in a Digital Malaysia.</p>
                    <button className='btn btn-download btn-blue'>View More</button>
                    </div>
                </Carousel.Item>
                <Carousel.Item>
                <div className='banner'>
                        <img src={employeeSvg} className='img-fluid' alt='Emplyee' />
                            <h3>EMPLOYEES</h3>
                            <h4>WHY THEY ARE IMPORTANT</h4>
                            <p>We rely on the skills and knowledge of Warga TM to drive our aspirations forward. Their hard work and dedication have made our digital and nation building objectives possible. At the same time, we are responsible for providing employees with livelihoods and fulfilling career paths.</p>
                    <button className='btn btn-download btn-blue'>View More</button>
                    </div>
                </Carousel.Item>
  
            </Carousel>
        </section>
    )
}
