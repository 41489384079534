import React from 'react'
import { Container } from 'react-bootstrap'
import OurProgress from './components/OurProgress';
import Chairman from './components/Chairman';
import Gceo from './components/Gceo';
import Gcfo from './components/Gcfo';
import Financial from './components/Financial';
import WhatWeDo from './components/WhatWeDo';
import Stakeholder from './components/Stakeholder';
import Priorities from './components/Priorities';
import Downloads from './components/Downloads';
import Banner from './components/Banner';

export default function Home() {
  return (
    <>    
   <Banner />
 <OurProgress />
 <Chairman />
 <Gceo />
 <Gcfo />
 <Financial />
 <WhatWeDo />
 <Stakeholder />
 <Priorities />
 <Downloads />
    </>
  );
}
