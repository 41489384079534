import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import gceo from '../../../assets/images/home/gceo.png';
import quote from '../../../assets/images/home/quote.svg';
import unifi from '../../../assets/images/home/unifi.png';
import tmone from '../../../assets/images/home/tmone.png';
import tmgroup from '../../../assets/images/home/Group.svg';

export default function Gceo() {
    return (
        <section className='section-gceo'>
            <div className='bg-2'>
                <Container fluid>
                <div className='side d-none d-sm-none d-md-block'>
            <img className='img-fluid' src={gceo} alt='' />
            </div>
                        <Row className='row-1'>
                            <Col>
                                {/* <img src={gceo} className='img-fluid' alt='gceo' /> */}
                                &nbsp;
                            </Col>
                            <Col>
                                <h1>MESSAGE FROM
                                    OUR <span>GCEO</span></h1>
                                <p><img className='img-fluid' src={quote} alt='quote' />
                                    &nbsp;2022 was the second year of our transformation programme that started in 2021 and we are delighted with the momentous progress we’ve seen for the year. We maintained a positive growth momentum in profitability for the year and accelerated our nation building efforts.</p>
                                <h3>Dato’ Imri Mokhtar</h3>
                                <h4>Managing Director /<br />
                                    Group Chief Executive Officer</h4>
                                <button className='btn-download btn-orange'>
                                    Read More
                                </button>
                            </Col>

                        </Row>
                
                    <Row className='box-holder'>
                        <Col className='cus-col' lg={4} md={6} sm={12} xs={12} >
                            <div className='box-first'>
                                <Row>

                                    <Col>
                                    <div class="table-responsive">
                                        <table className='table table-inv'>
                                            <tr>
                                                <td><h6>OUR NETWORK</h6></td>
                                                <td>&nbsp;</td>
                                            </tr>
                                            <tr>
                                                <td><h5>&#62; 690,000km </h5></td>
                                                <td> <h5><span>Domestic Cables</span></h5></td>
                                            </tr>
                                            <tr>
                                                <td><h5>&#62; 340,000km </h5></td>
                                                <td><h5><span>Submarine Cables</span></h5></td>
                                            </tr>
                                            <tr>
                                                <td><h6>OUR PEOPLE</h6></td>
                                                <td>&nbsp;</td>
                                            </tr>
                                            <tr>
                                                <td><h5 className='gap'>19,499 </h5></td>
                                                <td><h5 className='gap'><span>Warga TM</span></h5></td>
                                            </tr>
                                        </table>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        <Col className='cus-col'>
                            <Row>
                                <Col  className='cus-col'>
                                    <div className='box-second'>
                                        <img className='img-fluid' src={unifi} alt='unifi' />
                                        <br />
                                        <h4>3.5 million</h4>
                                        <h5>Customers</h5>
                                    </div>
                                </Col>
                                <Col className='cus-col'>
                                    <div className='box'>
                                        <Row>
                                            <Col md={4} sm={12}> <img className='img-fluid' src={tmone} alt='tmone' /></Col>
                                            <Col  >
                                                <h4>6,000</h4>
                                                <h5>Enterprises</h5>
                                            </Col>
                                        </Row>

                                    </div>
                                </Col>
                                <Col className='cus-col'>
                                    <div className='box'>
                                        <Row>
                                            <Col md={4} sm={12}> <img className='img-fluid' src={tmgroup} alt='tmgroup' /></Col>
                                            <Col >
                                                <h4>163</h4>
                                                <h5>Malaysian Service Provider</h5>
                                            </Col>
                                        </Row>

                                    </div>
                                </Col>
                            </Row>
                        </Col>


                    </Row>

                </Container>
            </div>
        </section>
    )
}
