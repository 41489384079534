import React from 'react'

export default function Stakeholder() {
  return (
    <section className='section-stakeholder'>
        <div className='menu-holder'>
            <a href='#' alt="link">Stakeholder Priorities</a>
            <a href='#' alt="link">Material Matters</a>
            <a href='#' alt="link">Market Trends</a>
            <a href='#' alt="link">Risk & Mitigation</a>
            <a href='#' alt="link">Value Creation Business Model</a>
            <a href='#' alt="link">Our Capitals</a>
            <a href='#' alt="link">Value Creation Strategy</a>
        </div>

    </section>
  )
}
