import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import unifi from '../../../assets/images/home/unifi.png';
import tmone from '../../../assets/images/home/tmone.png';
import tmgroup from '../../../assets/images/home/Group.svg';
import credence from '../../../assets/images/home/logo-credence 1.png';

export default function WhatWeDo() {
    return (
        <section className='section-www'>
            <div className='bg-2'>
                <Container>
                    <Row>
                        <Col className='title-holder'>
                            <h1>
                                WHAT <span>WE DO</span>
                            </h1>

                        </Col>
                        <Col>
                            <h2>WHO WE ARE & WHERE ARE WE HEADED</h2>
                            <p>Our organisation is at a pivotal moment, as we gear up for the next phase of our evolution towards becoming a human-centred TechCo. We do this by shaping a Digital Malaysia through technology that empowers communities, businesses and Government.</p>
                            <button className='btn btn-download btn-orange'>View More</button>

                        </Col>
                    </Row>
                    <h3>OUR KEY FOCUS 2024</h3>
                    <Row className='box-holder'>
                        <Col>
                            <div className='box'>
                                <div className='logo-holder'>
                                    <img className='img-fluid' src={unifi} alt='Unifi' />
                                </div>
                                <h4>Consumer & SMEs (B2C)</h4>
                                <ul>
                                    <li>FMC Mobile</li>
                                    <li>Content Acceleration
                                        & Monetisation</li>
                                    <li>Lifestyle Convergence</li>
                                    <li>SME Digital Ecosystem</li>
                                </ul>
                            </div>
                        </Col>
                        <Col>
                            <div className='box'>
                                <div className='logo-holder'>
                                    <Row>
                                        <Col> <img className='img-fluid multi' src={tmone} alt='TM One' /></Col>
                                        <Col> <img className='img-fluid multi' src={credence} alt='Credence' /></Col>
                                    </Row>

                                </div>
                                <h4>Consumer & SMEs (B2C)</h4>
                                <ul>
                                    <li>FMC Mobile</li>
                                    <li>Content Acceleration
                                        & Monetisation</li>
                                    <li>Lifestyle Convergence</li>
                                    <li>SME Digital Ecosystem</li>
                                </ul>
                            </div>
                        </Col>
                        <Col>
                            <div className='box'>
                                <div className='logo-holder'>
                                    <img className='img-fluid' src={tmgroup} alt='TM Group' />
                                </div>
                                <h4>Consumer & SMEs (B2C)</h4>
                                <ul>
                                    <li>FMC Mobile</li>
                                    <li>Content Acceleration
                                        & Monetisation</li>
                                    <li>Lifestyle Convergence</li>
                                    <li>SME Digital Ecosystem</li>
                                </ul>
                            </div>
                        </Col>
                    </Row>

                </Container>
            </div>
        </section>
    )
}
