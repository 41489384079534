import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

export default function OurProgress() {
    return (
        <section className='section-our-progress'>
            <Container>
                <Row>
                    <Col md={5} sm={12}>
                        <h1>OUR
                            PROGRESS
                            SO FAR</h1>
                        <button className='btn-download btn-orange'>
                            View More
                        </button>
                    </Col>
                    <Col md={7} sm={12} >
                        <Container>
                            <h2>Welcome to Telekom Malaysia Berhad’s (TM) Integrated Annual Report (IAR) 2022!</h2>
                            <p>Built on a strong foundation and heritage, TM paves its next era as a human-centred technology company. Our purpose and journey is to shape a Digital Malaysia that empowers communities, businesses and Government has come a long way, yet it is far from over; in fact, it has just begun.
                                <br />
                                <br />
                                We harness technology to create a better tomorrow for all. As Future Shapers, we connect our stakeholders to opportunities by enabling them with innovative solutions that help propel them closer to their next goals. Throughout this annual report, we feature imageries that reflect our commitment.
                            </p>
                          
                        </Container>
                    </Col>
                </Row>
            </Container>
        </section>

    )
}
